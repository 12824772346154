import React from "react"
import Layout from "../components/Layout/layout"
import AsideRight from '../components/Asides/Aside_right/Aside_right'
import ADS from '../components/Asides/ADS/Ads_left'
import NotFound from '../components/404/404';

const NotFoundPage = () => (
  <Layout>
    <ADS />
    <NotFound />
    <AsideRight />
  </Layout>
)

export default NotFoundPage
