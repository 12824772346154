import React from 'react';
import styles from './404.module.scss'


const NotFound = () => (
    <main className={styles.notfound}>
        <h1 className={styles.notfound__header}>404</h1>
        <p className={styles.notfound__desc}>Такой страницы не существует</p>
    </main>
)

export default NotFound;